import { Analytics } from 'analytics';
import googleAnalyticsPkg from '@analytics/google-analytics';

const googleAnalytics = googleAnalyticsPkg.default || googleAnalyticsPkg;

// Create analytics instance using the Analytics constructor
const analytics = Analytics({
  app: 'Co-Owners Website',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-HXP8GVQWN7'],
    }),
  ],
});

// Utility functions for tracking
export const trackPageView = () => {
  try {
    analytics.page();
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
};

export const trackEvent = (eventName: string, eventData?: Record<string, any>) => {
  try {
    analytics.track(eventName, eventData);
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

export { analytics };